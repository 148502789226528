import {BaseThunkType, InferActionsTypes} from "../Redux-store";
import {adminApi} from "../../Api/Api";
import {changePoints} from "./authReducer";
import {Books} from "./folderPageReducer";


let initialState = {
    FeedPosts: [] as Array<Books>,
    isAdded: false as boolean,
    showMore:true as boolean
}
type InitialState = typeof initialState;
export const feedReducer = (state = initialState, action: ActionsTypes): InitialState => {

    switch (action.type) {
        case "setFeedFromDB":
            return {
                ...state,
                FeedPosts: action.FeedPosts,
                isAdded: true
            }
        case "ShowMore":
            return{
                ...state,
                FeedPosts: [...state.FeedPosts, ...action.Feed],
            }
        case "addIdToBook":
            return {
                ...state,
                FeedPosts: state.FeedPosts.map( u => {
                    if(u._id == action.bookId && action.typeB==='Like'){
                        if(!u.like?.includes(action.id)) u.like?.push(action.id)
                    }else if(u._id == action.bookId && action.typeB==='Celebrate'){
                        if(!u.celebrate?.includes(action.id)) u.celebrate?.push(action.id)
                    }
                    return {...u}
                })

            }
        case "removeIdToBook":
            return {
                ...state,
                FeedPosts: state.FeedPosts.map( u => {
                    if(u._id == action.bookId && action.typeB==='Liked'){
                        let x = u.like?.filter(function (e) {
                            return e!=action.id
                        })
                        return {...u , like:x}
                    }else if(u._id == action.bookId && action.typeB==='Celebrated'){
                        let x = u.celebrate?.filter(function (e) {
                            return e!=action.id
                        })
                        return {...u , celebrate:x}
                    }
                    return {...u}
                })

            }
        case "setShowMore":
            return {
                ...state,
                showMore: false
            }
        default:
            return state
    }
}

export const actions = {
    setFeedFromDB: (FeedPosts: Array<Books>) => ({type: 'setFeedFromDB', FeedPosts} as const),
    ShowMore: (Feed: Array<Books>) => ({type: 'ShowMore', Feed} as const),
    addIdToBook: (id:any, bookId:any, typeB:string) => ({type: 'addIdToBook', id,bookId,typeB} as const),
    removeIdToBook: (id:any, bookId:any, typeB:string) => ({type: 'removeIdToBook', id,bookId, typeB} as const),
    setShowMore: () => ({type: 'setShowMore'} as const),
}
export const setFeedFromDB = (id: string,page=1): ThunkType => async (dispatch, getState) => {
    const book = await adminApi.getFeed(id,page).then(res => res);
    //console.log(book)
    dispatch(actions.setFeedFromDB(book));
}
export const ShowMoreFeed = (id: string,page=1): ThunkType => async (dispatch, getState) => {
    const book = await adminApi.getFeed(id,page).then(res => res);
    if(book.length) dispatch(actions.ShowMore(book));
    else {
        dispatch(actions.setShowMore());
    }
}
export const addIdToBook = (userId:any,bookId:any, type:string): ThunkType => async (dispatch, getState) => {
    dispatch(actions.addIdToBook(userId, bookId, type));
}
export const removeIdToBook = (userId:any,bookId:any,type:string): ThunkType => async (dispatch, getState) => {
    dispatch(actions.removeIdToBook(userId, bookId,type));
}


type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>