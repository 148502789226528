import {BaseThunkType, InferActionsTypes} from "../Redux-store";
import {adminApi} from "../../Api/Api";
import {changePoints} from "./authReducer";

interface BooksModer {
    _id: string
    name: string;
    author: string;
    moderation:string,
    language: string;
    format: string;
    pages?: number;
    audio?: number;
    allPages?: number;
    cover: string;
    deleteCover: string;
    status: string;
    createdAt: string;
    like?: Array<any>;
    celebrate?: Array<any>;
    userId: Array<any>;
}

let initialState = {
    bookModeration: [] as Array<BooksModer>,
    isAdded: false as boolean
}
type InitialState = typeof initialState;
export const moderatorReducer = (state = initialState, action: ActionsTypes): InitialState => {

    switch (action.type) {
        case "setModerBook":
            return {
                ...state,
                bookModeration: action.Books,
                isAdded: true
            }
        default: {
            return state;
        }
    }
}

export const actions = {
    setModerBooksFromDB: (Books: Array<BooksModer>) => ({type: 'setModerBook', Books} as const),
}

export const setModerBooksFromDB = (): ThunkType => async (dispatch, getState) => {
    const book = await adminApi.getBooksPending();
    dispatch(actions.setModerBooksFromDB(book));
}

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>