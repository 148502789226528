import {BaseThunkType, InferActionsTypes, store} from "../Redux-store";
import {adminApi} from "../../Api/Api";
import {setClearFolder} from "./folderPageReducer";
import {toast} from "react-toastify";
import {throws} from "assert";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {socket} from "../../App";


let initialState = {
        _id: '' as string,
        email: '' as string,
        language: 'English' as string,
        avatar: null as null | string,
        username: '' as string,
        lastName: '' as string,
        firstName: '' as string,
        gender: null as null | string,
        level: 0 as number,
        points: 1 as number,
        phone: '' as string,
        roles: '' as string | Array<string>,
        followers: [] as Array<any>,
        following: [] as Array<any>,
        Books: [] as Array<any>,
        Top_5_Books: [] as Array<any>,
        accessToken: null as null | string,
        refreshToken: null as null | string,
        showTop5Books: false as boolean,
        youtube: null as null | string,
        instagram: null as null | string,
        facebook: null as null | string,
        github: null as null | string,
        linkedin: null as null | string,
}

type initialStateType = typeof initialState;
export const authReducer = (state = initialState, action: ActionsTypes): initialStateType => {

    switch (action.type) {
        case "SET_AUTH_USER":
            return {
                ...state,
                ...action.payload
            }
        case "SET_FOLLOWERS":
            return{
                ...state,
                followers: [...state.followers, action.id]
            }
        case "SET_FOLLOWING":
            return{
                ...state,
                following: [...state.following, action.id]
            }
        case "delete_follow":
            return {
                ...state,
                followers: state.followers.filter( f => f != action.id)
            }
        case "delete_followings":
            return {
                ...state,
                following: state.following.filter( f => f != action.id)
            }
        case "changePoints":
            return{
                ...state,
                points: action.points,
                level: action.level,
            }
        case "updateAvatar":
            return{
                ...state,
                avatar: action.avatar
            }
        default: {
            return state;
        }
    }
}

export const actions = {
    setAuthUserAC: (payload: initialStateType) => ({type: 'SET_AUTH_USER', payload} as const),
    addFollowers: (id: any) => ({type: 'SET_FOLLOWERS', id} as const),
    addFollowings: (id: any) => ({type: 'SET_FOLLOWING', id} as const),
    deleteFollow: (id: any) => ({type: 'delete_follow', id} as const),
    deleteFollowings: (id: any) => ({type: 'delete_followings', id} as const),
    changePoints: (points: number,level:number) => ({type: 'changePoints', points,level} as const),
    updateAvatar: (avatar: string) => ({type: 'updateAvatar', avatar} as const),
}
export const updateAvatarTC = (avatar:string): ThunkType => async (dispatch: any) =>{
    dispatch(actions.updateAvatar(avatar))
}

export const authMeTC = (data?:any): ThunkType => async (dispatch: any) => {

    if(localStorage.getItem("profileIqura")){
        if(data) {
            dispatch(actions.setAuthUserAC(data));
        }
        else{
            try {
                let response = await adminApi.authMe();
                if(response==='Account deleted or not exists') {
                    localStorage.removeItem('profileIqura')
                }
                else dispatch(actions.setAuthUserAC(response));
            } catch (e:any) { }
        }
    }

}
export const deleteProfileTC = (): ThunkType => async (dispatch: any , getState) => {

    const clearProfile={
        _id: '',
        email: '',
        username: '',
        language: 'English',
        avatar: null,
        lastName: '',
        firstName: '',
        gender: null,
        level: 0,
        points: 1,
        phone: '',
        roles: '',
        followers: [],
        following: [],
        Books: [],
        accessToken: null,
        refreshToken: null,
        Top_5_Books:[],
        showTop5Books:false,
        instagram: null,
        facebook: null,
        github: null,
        youtube: null,
        linkedin: null,
    }
    dispatch(actions.setAuthUserAC(clearProfile));
    dispatch(setClearFolder());

}
export const changePoints = (points:number,level:number): ThunkType => async (dispatch: any , getState) => {

    dispatch(actions.changePoints(points,level));
}
export const loginTC = (data: { email: string, password: string },history:any, setError?:any): ThunkType => async (dispatch: any) => {
    try {
        let response = await adminApi.loginMe(data);
        await localStorage.setItem("profileIqura", JSON.stringify({
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
            _id: response._id
        }));
        dispatch(authMeTC(response));
        socket.connect();
        socket.emit('connectUser',response._id)
        history.push({pathname: '/'});

        toast.info('You are on the main page', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } catch (e) {
        toast.error('The email or password is incorrect', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setError("login", {
            type: "manual",
            message: "The email or password is incorrect"
        });
    }
}
export const registerTC = (data: any ,history:any): ThunkType => async (dispatch: any) => {
    try {
        let response = await adminApi.signUp(data);

        toast.success('Account created!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        history.push({pathname: '/login'});
    } catch (e) {
        alert(e);
        toast.error(`${e}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    }
}

export const saveInfoProfileTC = (data:any) => async (dispatch:any,getState:any) => {
    let userID = getState().auth.id;
    let response = await adminApi.saveInfoProfile(data);
    if (response.resultCode == 0) dispatch(authMeTC());
    else{
        let message = response.data.messages[0];
        return Promise.reject(message);
    }
}

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>