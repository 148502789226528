import {Action, applyMiddleware, combineReducers, createStore} from "redux";
import {showReducer} from "./reducers/showPageReducer";
import * as thunk from "redux-thunk";
import {ThunkAction} from "redux-thunk";
import {folderReducer} from "./reducers/folderPageReducer";
import {authReducer} from "./reducers/authReducer";
import {adminFolderReducer} from "./reducers/adminFolderPageReducer";
import {friendsReducer} from "./reducers/friendsReducer";
import {feedReducer} from "./reducers/feedPage";
import {moderatorReducer} from "./reducers/moderatorPageReducer";
import {newsReducer} from "./reducers/newsPage";
import {statusReducer} from "./reducers/StatusReducer";

const appReducer= combineReducers({
    showPage: showReducer,
    folderPage: folderReducer,
    auth: authReducer,
    adminFolderPage: adminFolderReducer,
    friendPage:friendsReducer,
    feedPage:feedReducer,
    moderatePage:moderatorReducer,
    newsPage:newsReducer,
    onlineStatus:statusReducer,
});
const RootReducers = (state:any, action:any) => {
    if(action.type === 'DESTROY_SESSION')
        state = undefined;

    return appReducer(state, action);
};
type RootReducerType = typeof RootReducers;
export type AppStateType = ReturnType<RootReducerType>
export type InferActionsTypes<T> = T extends { [keys: string]: (...args: any[]) => infer U } ? U : never
export type BaseThunkType<A extends Action = Action, R = Promise<void>> = ThunkAction<R, AppStateType, unknown, A>

export let store = createStore(RootReducers,applyMiddleware(thunk.default));

// @ts-ignore
window.store = store;

export default store;