import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {InitStatus} from "../Redux/reducers/StatusReducer";
import {socket} from "./../App";
import {AppStateType} from "../Redux/Redux-store";

export const useSocket = () => {
    const {_id } = useSelector((state: AppStateType) => state.auth)
    const {pathname} = useLocation()
    const dispatch = useDispatch();
    useEffect(() => {
        socket.on("connect", () => {
            //console.log("connected to socket")
        });
        socket.emit('connectUser', _id);
        socket.on("online",  (value: any) => {
            dispatch(InitStatus(new Set(JSON.parse(value))))
        });
        return () => {
            socket.off('online');
            socket.off('connect');
        }
    }, [_id,dispatch,socket]);
    useEffect(()=> {
        socket.emit('connectUser', _id);
    },[pathname])
    return true;
};