import './header.css';
import React, {useEffect, useState} from "react";
import {NavLink, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "../../Redux/Redux-store";
import {authMeTC} from "../../Redux/reducers/authReducer";
import {socket} from "../../App";


export const logo = require("../../img/Logo.png");

let Myprofile = require("../../img/profile.png");
let settings = require("../../img/settings.png");
let log_out = require("../../img/log_out.png");
export const logoIqura = require("../../img/logo2.png");

export const Header = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isAuth = useSelector((state: AppStateType) => state.auth.email);
    const {language,roles} = useSelector((state: AppStateType) => state.auth);
    const cover = useSelector((state: AppStateType) => state.auth.avatar) || 'https://i.ibb.co/GM5XDtL/Artboards-Diversity-Avatars-by-Netguru-18.png';
    const [isHeader_Profile_Info, setIsHeader_Profile_Info] = useState(false);
    const [isModerator, setIsModerator] = useState(false);
    useEffect(()=> {
        if (roles.includes('moderator') || roles.includes('admin')) setIsModerator(true);
    },[roles])

    if (!isAuth && localStorage.getItem("profileIqura")) dispatch(authMeTC());


    return (
        <header className={!isAuth ? `off_view` : ''}>
            <div className={`Wrapper_container`}>
                <div className="Wrapper_container__left" onClick={() => history.push('/')}>
                    <img className='logo2' src={logo}/>
                    <div className="logoCorrector">
                        <img src={logoIqura} alt='React Logo'/>
                    </div>

                </div>
                <div className="Wrapper_container__right">
                    {
                        !isAuth ? <><NavLink className='Button_Sign_Up' to={'/register'}>
                                Sign Up
                            </NavLink><NavLink className='Button_Sign_In' to={'/login'}>
                                Log In
                            </NavLink></>
                            : <>
                                {language === 'English' ? <>
                                    <NavLink className='Button_Info' to={'/'} id='Feed'>
                                        Feed
                                    </NavLink>
                                    <NavLink className='Button_Info' to={'/news'} id='News'>
                                        News
                                    </NavLink>

                                </> : <>
                                    <NavLink className='Button_Info' to={'/'} id='Feed'>
                                        Стрічка
                                    </NavLink>
                                    <NavLink className='Button_Info' to={'/news'} id='News'>
                                        Газета
                                    </NavLink>

                                </>
                                }

                                <NavLink className='Button_AddBook' to={'/addBook'}>
                                    {language === 'English' ? 'Create a book' : 'Додати книгу'}
                                </NavLink>
                                <div className='Header_avatarRelative'
                                     onMouseEnter={() => setIsHeader_Profile_Info(!isHeader_Profile_Info)}
                                     onClick={() => setIsHeader_Profile_Info((!isHeader_Profile_Info))}>
                                    <div className='Header_avatar'>
                                        <img src={cover}/>
                                    </div>
                                    <span className='Arrow'>{'>'}</span>
                                </div>
                            </>
                    }
                    {isHeader_Profile_Info &&
                        <HeaderProfileInfo modal={setIsHeader_Profile_Info} isModerator={isModerator}/>
                    }

                </div>
            </div>
        </header>
    )
}

interface IProps {
    img: any,
    text: string,
    funka?: any
}

const ImgHeaderProfile: React.FC<IProps> = ({img, text, funka}) => {
    return (
        <div className='ImgHeaderProfile__main' onClick={funka}>
            <img src={img} alt=""/>
            <span>{text}</span>
        </div>)
}

const HeaderProfileInfo: React.FC<{ modal?: any, isModerator?:boolean }> = ({modal, isModerator=false}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {level, firstName, lastName, points, language, roles} = useSelector((state: AppStateType) => state.auth);


    let pointsEnd = level * 150;
    let range0_100 = Math.ceil(points * 100 / pointsEnd);
    const log_outF = () => {
        localStorage.removeItem('profileIqura');
        dispatch({type: 'DESTROY_SESSION'});
        history.push('/login');
        modal(false)
        socket.close();
    }
    const ProfilePage = () => {
        history.push('/profile');
        modal(false)
    }
    const editProfile = () => {
        history.push('/editprofile');
        modal(false)
    }
    const moderationPage = () => {
        history.push('/account_admin_moderation');
        modal(false)
    }
    return (
        <div className={`Header_Profile_Info ${isModerator ? 'moderatorMode' : ''}`} onMouseLeave={() => modal(false)}>

            <div className='Header_Profile_Info__UP'>
                <div className='Header_Profile_Info__UP_IMG'>
                    <img src='https://i.ibb.co/jrRHycK/level.png' alt=""/>
                    <span>{level}</span>
                </div>
                <div className='Header_Profile_Info_Contaniner'>
                    <div className='Header_Profile_Info_Contaniner__name'>{firstName} {lastName}</div>
                    <div className='Header_Profile_Info_Contaniner__line'>
                        <div
                            className='Header_Profile_Info_Contaniner__line__progress'>{points} / {pointsEnd}</div>
                        <div className='Header_Profile_Info_Contaniner__lineBlue'
                             style={{width: `${range0_100 < 10 ? 10 : range0_100}%`}}/>
                        <div
                            className='Header_Profile_Info_Contaniner__Lowername'>{pointsEnd - points} {language === 'English' ? 'point' : 'балів'} {pointsEnd - points != 1 && 's' && language === 'English'} {language === 'English' ? 'left to level' : 'залишилося '} {language === 'English' && level + 1}</div>
                    </div>
                </div>
            </div>

            <div className="Header_Profile_Info__Down">
                <div className="Header_Profile_Info__Down__container">
                    <ImgHeaderProfile img={Myprofile} text={language === 'English' ? 'My profile' : 'Профіль'}
                                      funka={ProfilePage}/>
                    <ImgHeaderProfile img={settings} text={language === 'English' ? 'Settings' : 'Налаштування'}
                                      funka={editProfile}/>
                    {(roles.includes('admin') || roles.includes('moderator')) &&
                        <ImgHeaderProfile img={settings} text={language === 'English' ? 'Moder' : 'Модер'}
                                          funka={moderationPage}/>
                    }
                    <ImgHeaderProfile img={log_out} text={language === 'English' ? 'Log out' : 'Вийти'}
                                      funka={log_outF}/>
                </div>
            </div>

        </div>
    )
}