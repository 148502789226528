import {BaseThunkType, InferActionsTypes} from "../Redux-store";

let initialState = {
    ONLINE_USERS_SET: new Set as any,
}
type InitialState = typeof initialState;
export const statusReducer = (state=initialState, action:ActionsTypes):InitialState =>{
    switch (action.type) {
        case 'INIT':
            return{
                ...state,
                ONLINE_USERS_SET: action.data,
            }
        default: {
            return state;
        }
    }
}

export const actions = {
    setProgram: (data:any) => ({type: 'INIT', data} as const),
}
export const InitStatus = (data: any): ThunkType => async (dispatch, getState) => {
    dispatch(actions.setProgram(data))
}
type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>