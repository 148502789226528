import React from 'react';
import './Preloader.css'
const WinterPreloaderImg = require('./../../img/loading-state2.gif')

const WinterPreloader = () => {
    return (
        <div className='WinterPreloader__Wrapper'>
            <img src={WinterPreloaderImg} width="300px"/>
        </div>
    );
};

export default WinterPreloader;