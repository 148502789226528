import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

instance.interceptors.request.use((config) => {
  let accessToken = JSON.parse(String(localStorage.getItem("profileIqura")));
  //@ts-ignore
  config.headers.Authorization = `Bearer ${
    accessToken && accessToken.accessToken
  }`;
  return config;
});
instance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status == 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const access = JSON.parse(String(localStorage.getItem("profileIqura")));

        const responce = await instance
          .post(`/auth/refresh`, { token: access.refreshToken })
          .then((res) => res.data.accessToken);
        access.accessToken = responce;
        await localStorage.setItem("profileIqura", JSON.stringify(access));
        return instance.request(originalRequest);
      } catch (e) {
        await localStorage.removeItem("profileIqura");
        console.log("НЕ АВТОРЕЗОВАН");
      }
    }
    throw error;
  },
);

export const adminApi = {
  getGoods() {
    return instance.get(`/cars`).then((res) => res.data);
  },
  authMe() {
    return instance.get(`/auth/authMe`).then((res) => res.data);
  },
  addNewFolder(data: any) {
    return instance.post(`/books`, { ...data }).then((res) => res.data);
  },
  updatePages(data: any) {
    return instance
      .put(`/books/updatePages`, { ...data })
      .then((res) => res.data);
  },
  updateBook(data: any) {
    return instance
      .put(`/books/updateBook`, { ...data })
      .then((res) => res.data);
  },
  deleteBook(BookId: any, userId: any) {
    const mainId = `?BookId=${BookId}&userId=${userId}`;
    return instance.delete(`/books${mainId}`).then((res) => res.data);
  },
  loginMe(data: { email: string; password: string }) {
    const { email, password } = data;
    return instance
      .post(`/auth/signIn`, { email, password })
      .then((res) => res.data);
  },
  signUp(data: any) {
    return instance.post(`/auth/signUp`, data).then((res) => res.data);
  },
  addNewBook(data: any) {
    return instance.post(`/users/books`, data).then((res) => res.data);
  },
  getBooks(id: string) {
    return instance.get(`/users/books/${id}`).then((res) => res.data);
  },
  getBooksPending() {
    return instance.get(`/books/pending`).then((res) => res.data);
  },
  addBook(formData: any) {
    return axios.post("https://api.imgbb.com/1/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getUserProfile(username: string) {
    return instance.get(`/users/${username}`).then((res) => res.data);
  },
  follow(data: { id: any; currentId: any }) {
    return instance.post(`/users/follow/`, data).then((res) => res.data);
  },
  unFollow(data: { id: any; currentId: any }) {
    return instance.post(`/users/unfollow/`, data).then((res) => res.data);
  },
  getAllUsers(page: number, limit: number) {
    const mainId = `?page=${page}&limit=${limit}`;
    return instance.get(`/users/some/get${mainId}`).then((res) => res.data);
  },
  findByUsername(username: string) {
    const mainId = `?username=${username}`;
    return instance.get(`/users/search/list${mainId}`).then((res) => res.data);
  },
  getFriends(id: any, page = 1, limit = 7, sort = "no", path = "following") {
    const mainId = `?currentId=${id}&page=${page}&limit=${limit}&sort=${sort}&path=${path}`;
    return instance
      .get(`/users/feed/friends/${mainId}`)
      .then((res) => res.data);
  },
  getSearch(id: any, search: string, path = "following") {
    const mainId = `?currentId=${id}&search=${search}&path=${path}`;
    return instance.get(`/users/feed/search/${mainId}`).then((res) => res.data);
  },
  getLike(id: any, page = 1, limit = 2, path = "like") {
    const mainId = `?bookId=${id}&page=${page}&limit=${limit}&path=${path}`;
    return instance.get(`/books/getLike/${mainId}`).then((res) => res.data);
  },
  getLikeSearch(id: any, search: string, path = "like") {
    const mainId = `?bookId=${id}&search=${search}&path=${path}`;
    return instance
      .get(`/books/getLikeSearch/${mainId}`)
      .then((res) => res.data);
  },
  addToTopFive(bookId: any) {
    return instance
      .get(`/users/addToTopFive/${bookId}`)
      .then((res) => res.data);
  },
  deleteBookFromTop5(bookId: any) {
    return instance
      .delete(`/users/deleteBookFromTop5/${bookId}`)
      .then((res) => res.data);
  },
  deleteUsersBook(bookId: any, userId: any) {
    const mainId = `?bookId=${bookId}&userId=${userId}`;
    return instance
      .delete(`/books/deleteUsersBook/${mainId}`)
      .then((res) => res.data);
  },
  deleteBooksModerator(Array: Array<any>) {
    return instance
      .delete(`/books/deleteBooksModerator/`, { data: Array })
      .then((res) => res.data);
  },
  addLikeToBook(bookId: any, type: string) {
    return instance
      .post(`/books/like`, { BookId: bookId, type: type })
      .then((res) => res.data);
  },
  acceptBookModerator(Array: Array<any>) {
    return instance
      .post(`/books/moderatorStatus`, Array)
      .then((res) => res.data);
  },
  sendReport(dto: any) {
    return instance.post(`/report/send`, dto).then((res) => res.data);
  },
  getReport() {
    return instance.get(`/report/get`).then((res) => res.data);
  },
  deleteReport(id: string) {
    return instance.delete(`/report/delete/${id}`).then((res) => res.data);
  },
  saveInfoProfile(data: any) {
    return instance.put(`/users/editProfile`, data).then((res) => res.data);
  },
  getFeed(id: any, page = 1) {
    const mainId = `?currentId=${id}&page=${page}&limit=${20}`;
    return instance
      .get(`/users/feed/timeline${mainId}`)
      .then((res) => res.data);
  },
  getFeedGlobal(id: any, page = 1) {
    const mainId = `?currentId=${id}&page=${page}&limit=${20}`;
    return instance
      .get(`/users/feed/timeLineGlobal${mainId}`)
      .then((res) => res.data);
  },
  deleteInfoAboutdeleetdUser(id: string) {
    return instance
      .delete(`/books/deleteInfoAboutdeleetdUser/${id}`)
      .then((res) => res.data);
  },
  getNews() {
    return instance.get(`/notification/get`).then((res) => res.data);
  },
};
