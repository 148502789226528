import {BaseThunkType, InferActionsTypes} from "../Redux-store";
import {adminApi} from "../../Api/Api";
import {changePoints} from "./authReducer";


interface infoType {
    id: string,
    number: number
}

export interface Books {
    rating?: number;
    _id: string
    name: string;
    author: string;
    language: string;
    format: string;
    pages?: number;
    audio?: number;
    allPages?: number;
    cover: string;
    deleteCover: string;
    status: string;
    createdAt: string;
    like?: Array<any>;
    celebrate?: Array<any>;
    userId: any;
}

let initialState = {
    ArrayBooks: [] as Array<Books>,
    Top_5_Books: [] as Array<Books>,
    isAdded: false as boolean
}
type InitialState = typeof initialState;
export const folderReducer = (state = initialState, action: ActionsTypes): InitialState => {

    switch (action.type) {
        case "setFromDB":
            return {
                ...state,
                ArrayBooks: action.Books,
                Top_5_Books: action.Top_5_Books,
                isAdded: true
            }
        case "deleteBookById":
            return {
                ...state,
                ArrayBooks: state.ArrayBooks.filter(f => f._id != action.bookId),
            }
        case "deleteTOP5BookById" :
            return {
                ...state,
                Top_5_Books: state.Top_5_Books.filter(f => f._id != action.bookId),
            }
        case "addNewFolder":
            return {
                ...state,
                ArrayBooks: [action.folderData, ...state.ArrayBooks],
            }
        case "addToTopeFive":
            return {
                ...state,
                Top_5_Books: [...state.Top_5_Books, action.folderData],
            }
        case "changePages":
            return {
                ...state,
                ArrayBooks: state.ArrayBooks.map(u => {
                    if (u._id === action.bookId) {
                        if (action.pages === action.allPages) return {...u, pages: action.pages, status: 'Finished'};
                        else return {...u, pages: action.pages, status: 'Reading now'};
                    }
                    return {...u};
                })
            }
        case "changeAudio":
            return {
                ...state,
                ArrayBooks: state.ArrayBooks.map(u => {
                    if (u._id === action.bookId) {
                        if (action.audio === 100) return {...u, audio: 100, status: 'Finished'};
                        else return {...u, audio: action.audio, status: 'Reading now'};
                    }
                    return {...u};
                })
            }
        case "updateBook":
            return {
                ...state,
                ArrayBooks: state.ArrayBooks.map(u => {
                    if (u._id === action.payload._id) {
                        return {...u, name: action.payload.name, author: action.payload.author, format: action.payload.format, language: action.payload.language, rating: action.payload.rating};
                    }
                    return {...u};
                })
            }
        case "clearFromDB":
            return {
                ...state,
                ArrayBooks: action.folderData,
                isAdded: false
            }
        default: {
            return state;
        }
    }
}

export const actions = {
    deleteId: (folderID: string, productID: string) => ({type: 'deleteId', folderID, productID} as const),
    deleteBookById: (bookId: string) => ({type: 'deleteBookById', bookId} as const),
    deleteTOP5BookById: (bookId: string) => ({type: 'deleteTOP5BookById', bookId} as const),
    addNewFolder: (folderData: Books) => ({type: 'addNewFolder', folderData} as const),
    addToTopeFive: (folderData: Books) => ({type: 'addToTopeFive', folderData} as const),
    setFromDB: (Books: Array<Books>, Top_5_Books: Array<Books>) => ({type: 'setFromDB', Books, Top_5_Books} as const),
    clearFromDB: (folderData: Array<Books>) => ({type: 'clearFromDB', folderData} as const),
    changePages: (bookId: string, pages: number, allPages: number, status: string) => ({
        type: 'changePages',
        bookId,
        pages,
        allPages,
        status
    } as const),
    changeAudio: (bookId: string, audio: number, status: string) => ({
        type: 'changeAudio',
        bookId,
        audio,
        status
    } as const),
    updateBook: (payload: any) => ({type: 'updateBook', payload} as const),
    setCarToTheFolder: (folderId: string, carFull: infoType) => ({
        type: 'iqura/folder/setCarToTheFolder',
        folderId,
        carFull
    } as const),

}

export const deleteCarThunk = (folderId: string, productID: string): ThunkType => async (dispatch, getState) => {
    if (dispatch(actions.deleteId(folderId, productID))) {
        const carToSend = await getState().folderPage.ArrayBooks.find(f => f._id == folderId);
        //await adminApi.updateFolderName(folderId,carToSend);
    }
}
export const setCarToFolderThunk = (folderId: string, carId: number): ThunkType => async (dispatch, getState) => {
    let newId = '323';/*uuid.v4();*/
    let car = {id: newId, number: carId};
    if (dispatch(actions.setCarToTheFolder(folderId, car))) {
        const carToSend = await getState().folderPage.ArrayBooks.find(f => f._id == folderId);
        //await adminApi.updateFolderName(folderId,carToSend);
    }
}
export const setCarFromDB = (id: string): ThunkType => async (dispatch, getState) => {
    const book = await adminApi.getBooks(id).then(res => res);
    dispatch(actions.setFromDB(book.Books, book.Top_5_Books));
}

export const addNewBook = (data: any, url: string,roles:any): ThunkType => async (dispatch, getState) => {
    try {
        data.cover = url;
        data.status = 'JustAdded';
        data.pages = 0;
        const Book = await adminApi.addNewBook(data);
        if(roles.includes('admin') || roles.includes('moderator') ) dispatch(actions.addNewFolder(Book));
    } catch (e) {
        throw e
    }
}
export const addToTopeFiveThunk = (data: any, bookId: string): ThunkType => async (dispatch, getState) => {
    try {
        await adminApi.addToTopFive(bookId).then();
        dispatch(actions.addToTopeFive(data));
    } catch (e) {

    }

}

export const changePages = (dto: any, setActive: any, format:string): ThunkType => async (dispatch, getState) => {
    if(format==='Book' && dto.pages===dto.allPages) dto.status ='Finished';
    try {
        await adminApi.updatePages(dto).then(r => {
            if (r.status === 'ok') {
                if(format==='Book') dispatch(actions.changePages(dto._id, dto.pages, dto.allPages, dto.status));
                else dispatch(actions.changeAudio(dto._id, dto.audio, dto.status));
                dispatch(changePoints(r.points, r.level));
                setActive(false);
            }
        });
    } catch (e) {

    }
}
export const updateBook = (dto: any, setActive: any,format:string): ThunkType => async (dispatch, getState) => {
    if(format==='Book' && dto.pages===dto.allPages) dto.status ='Finished';
    try {
        await adminApi.updateBook(dto).then(r => {
            if (r.status === 'ok') {
                if(format==='Book') dispatch(actions.changePages(dto._id, dto.pages, dto.allPages, dto.status));
                else dispatch(actions.changeAudio(dto._id, dto.audio, dto.status));
                dispatch(changePoints(r.points, r.level));
                dispatch(actions.updateBook(dto));
                setActive(false);
            }
        });
    } catch (e) {

    }
}
export const deleteBook = (BookId: any, userId: any): ThunkType => async (dispatch, getState) => {
    try {
        await adminApi.deleteBook(BookId, userId);
        dispatch(actions.deleteBookById(BookId));
    } catch (e) {
        console.log(e);
    }
}
export const deleteTOP5Book = (BookId: any): ThunkType => async (dispatch, getState) => {
    try {
        await adminApi.deleteBookFromTop5(BookId).then();
        dispatch(actions.deleteTOP5BookById(BookId));
    } catch (e) {
        console.log(e);
    }
}

export const setClearFolder = (): ThunkType => async (dispatch, getState) => {
    dispatch(actions.clearFromDB([]))
}

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>