import {BaseThunkType, InferActionsTypes} from "../Redux-store";
import {adminApi} from "../../Api/Api";


let initialState = {
    _id: '' as string,
    followersProfile: [] as Array<any>,
    following: [] as Array<any>,
    followingProfile: [] as Array<any>,
    isUse: false as boolean,
    isDisable: false as boolean,
    isDisableFollowers: false as boolean,
}

type initialStateType = typeof initialState;
export const friendsReducer = (state = initialState, action: ActionsTypes): initialStateType => {

    switch (action.type) {
        case "SET_FRIEND":
            return {
                ...state,
                following: [...state.following, action.payload],
                isUse: true
            }
        case "SET_ONEMOREFRIEND":
            return {
                ...state,
                followingProfile: [...state.followingProfile, ...action.payload],
            }
        case "setOneMoreFollowers":
            return {
                ...state,
                followersProfile: [...state.followersProfile, ...action.payload],
            }
        case "setDisableButtonForFollowing":
            return {
                ...state,
                isDisable: true,
            }
        case "setDisableButtonForFollowers":
            return {
                ...state,
                isDisableFollowers: true,
            }
        case "setDefaultArray":
            return {
                ...state,
                followingProfile: [],
                isDisable: false,
            }
        case "setDefaultArrayFollowers":
            return {
                ...state,
                followersProfile: [],
                isDisableFollowers: false,
            }
        default: {
            return state;
        }
    }
}

export const actions = {
    setFriend: (payload: initialStateType) => ({type: 'SET_FRIEND', payload} as const),
    setOneMoreFriend: (payload: Array<any>) => ({type: 'SET_ONEMOREFRIEND', payload} as const),
    setOneMoreFollowers: (payload: Array<any>) => ({type: 'setOneMoreFollowers', payload} as const),
    setDisableButtonForFollowing: () => ({type: 'setDisableButtonForFollowing'} as const),
    setDisableButtonForFollowers: () => ({type: 'setDisableButtonForFollowers'} as const),
    setDefaultArray: () => ({type: 'setDefaultArray'} as const),
    setDefaultArrayFollowers: () => ({type: 'setDefaultArrayFollowers'} as const),
}

export const setFriends = (id: any, limit = 7): ThunkType => async (dispatch: any) => {
    try {
        const response = await adminApi.getFriends(id, 1, limit, 'yes');
        dispatch(actions.setFriend(response.following))
    } catch (e) {
        console.log("error");
    }
}

export const ShowMoreFriends = (id: string, page = 1): ThunkType => async (dispatch, getState) => {
    try {
        const response = await adminApi.getFriends(id, page, 20, 'no');
        if (!response.following.length) dispatch(actions.setDisableButtonForFollowing());
        else dispatch(actions.setOneMoreFriend(response.following))
    } catch (e) {
        console.log("FUCK");
    }
}
export const ShowMoreFollowers = (id: string, page = 1): ThunkType => async (dispatch, getState) => {
    try {
        const response = await adminApi.getFriends(id, page, 20, 'no', 'followers');
        if (!response.followers.length) dispatch(actions.setDisableButtonForFollowers());
        else dispatch(actions.setOneMoreFollowers(response.followers))
    } catch (e) {
        console.log("FUCK");
    }
}

export const DeleteArray = (): ThunkType => async (dispatch, getState) => {

    dispatch(actions.setDefaultArray());
}
export const DeleteArrayFollowers = (): ThunkType => async (dispatch, getState) => {

    dispatch(actions.setDefaultArrayFollowers());
}

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>