import {BaseThunkType, InferActionsTypes} from "../Redux-store";
import {adminApi} from "../../Api/Api";
import {changePoints} from "./authReducer";
import {Books} from "./folderPageReducer";


let initialState = {
    newsPosts: [] as Array<any>,
    isAdded: false as boolean,
    showMore:true as boolean
}
type InitialState = typeof initialState;
export const newsReducer = (state = initialState, action: ActionsTypes): InitialState => {

    switch (action.type) {
        case "setNewsFromDB":
            return {
                ...state,
                newsPosts: action.newsPosts,
                isAdded: true
            }

        case "setShowMore":
            return {
                ...state,
                showMore: false
            }
        default:
            return state
    }
}

export const actions = {
    setNewsFromDB: (newsPosts: Array<any>) => ({type: 'setNewsFromDB', newsPosts} as const),
    setShowMore: () => ({type: 'setShowMore'} as const),
}
export const setNewsThunk = (): ThunkType => async (dispatch, getState) => {
    if(!getState().newsPage.newsPosts.length){
        const news = adminApi.getNews().then(r => {
            dispatch(actions.setNewsFromDB([...r]));
        });
    }


}

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>