import "./App.css";
import { Route, Switch, useLocation } from "react-router-dom";
import { Header } from "./UI/Header/Header";
import React, { useEffect, Suspense, lazy } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "./Redux/Redux-store";
import { ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";

import "./Adaptive/HeaderAdaptive.css";
import "./Adaptive/usersProfileAdaptive.css";
import "./Adaptive/recentUserAdaptive.css";
import "./Adaptive/modalAdaptive.css";

import { io } from "socket.io-client";
import WinterPreloader from "./UI/Preloader/WinterPreloader";
import { useSocket } from "./Hooks/useSocket";

const Login = lazy(() => import("./UI/loginForm/Login"));
const RegisterForm = lazy(() => import("./UI/RegisterForm/RegisterForm"));
const Feed = lazy(() => import("./UI/FeedPage/Feed"));
const ProfilePage = lazy(() => import("./UI/Profile/ProfilePage"));
const ProfilePageNewUser = lazy(
  () => import("./UI/Profile/ProfilePageNewUser"),
);
const EditProfile = lazy(() => import("./UI/editProfile/editProfile"));
const Rules = lazy(() => import("./UI/RegisterForm/rules"));
const Moderation = lazy(() => import("./UI/CRM/Moderation"));
const Distinguish = lazy(() => import(".//UI/CRM/Distinguish"));
const Crm = lazy(() => import("./UI/CRM/CRM"));

const URL = process.env.REACT_APP_BASE_WS_URL ?? "";
export const socket = io(URL, { autoConnect: true });

const App = () => {
  const { _id, roles } = useSelector((state: AppStateType) => state.auth);
  const isOnline = useSocket();

  injectStyle();
  return (
    <div className="Wrapper">
      <Header />
      <ToastContainer />
      <Suspense fallback={<WinterPreloader />}>
        <Switch>
          <Route path="/login" render={() => <Login />} />
          <Route path="/register" render={() => <RegisterForm />} />
          <Route path="/addBook" render={() => <Feed addbook={true} />} />
          <Route
            path="/profile"
            render={() => <ProfilePage isOwner={true} />}
          />
          <Route path="/user" render={() => <ProfilePageNewUser />} />
          <Route path="/list" render={() => <Feed userList={true} />} />
          <Route path="/news" render={() => <Feed news={true} />} />
          {_id && <Route path="/editProfile" render={() => <EditProfile />} />}

          <Route path="/rules" render={() => <Rules />} />
          {roles.includes("admin") && (
            <Route path="/account_admin_crm" render={() => <Crm />} />
          )}
          {(roles.includes("admin") || roles.includes("moderator")) && (
            <Route
              path="/account_admin_moderation"
              render={() => <Distinguish />}
            />
          )}
          {(roles.includes("admin") || roles.includes("moderator")) && (
            <Route
              path="/account_admin_accepter"
              render={() => <Moderation />}
            />
          )}

          <Route path="/" render={() => <Feed />} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
